import React from 'react'
import Home from 'components/home/Home';

function Index() {
  return (
    <Home />
  )
}

export default Index
