import React from 'react'
import { Grid } from '@mui/material'
import Title from 'components/commons/typo/Title'
import ItemsList from 'components/item/ItemsList'
import clsx from 'clsx'
import styles from './HomePage.module.scss'
import Image from "components/commons/Image";
import { useWidth } from "js/hook";


function Home() {

  const width = useWidth();

  return (
    <div className={styles.homepage}>
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        className={clsx(['banner', width])}
      >
        <Grid item xs={12} sm={8} md={9}>
          <div className="title">Improve your vocabulary</div>
          <div className="subtitle">via your favorite media !</div>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <Image src="/images/book.svg" alt={'book'} width={'220'} height={'240'} />
        </Grid>
      </Grid>
      <div className="welcome">
        <Title options={{ h: 1 }}>Welcome to skreept</Title>
        <div className="text">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam ut porttitor erat, at rhoncus quam. Mauris tempus
          nisi a odio interdum, ut mollis nulla tincidunt. Quisque rhoncus fermentum venenatis. Vestibulum pharetra leo
          sed libero posuere sodales. Donec sit amet volutpat metus. Nunc auctor magna cursus consequat placerat. Nullam
          bibendum mauris sapien, a commodo quam molestie vel. Curabitur interdum ut mauris a gravida.
        </div>
      </div>
      <Title options={{ h: 2 }}>Latest media added</Title>
      <ItemsList pagination={'infinite'} showFilters={false} />
    </div>
  )
}

export default Home
